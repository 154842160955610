.plan-features-available {
  display: inline-flex;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
  background-color: #0c9707;
  text-align: center;
  color: white;
  font-weight: bolder;
}

.plan-features-unavailable {
  display: inline-flex;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
  background-color: #d9534f;
  text-align: center;
  color: white;
  font-weight: bolder;
}
.feature-list li {
  border-top: 1px solid #e6e6e6;
  line-height: 36px;
  text-align: left;
  padding-left: 16px;
}
